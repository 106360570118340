<template>
  <v-footer dark padless class="baloo_paaji_regular">
    <v-card class="flex" flat tile style="background: transparent">
      <!--<v-card-text class="rodade_gradiente">-->
        <!--<div>-->
          <!--<h3 class="txt_branco"></h3>-->
        <!--</div>-->
      <!--</v-card-text>-->
      <v-card-text class=" fundoFooter">
        <v-container >
            <v-row justify="center" no-gutter>
            <v-col cols="12" sm="12" md="12" lg="4">
              <h2 class="txt_branco" style="font-weight: bold">Central e Atendimento</h2>
              <br>
              <p style="text-align: center" class="txt_branco">
                Ficou com alguma Dúvida? Entre em contato:
              </p>
              <v-btn name="telefone" class="telefone1" outlined @click="IrParaUrl('tel:+553333226363', '_self')" >
                <v-icon>mdi-phone</v-icon>
                <h2>(33)3322-6363</h2>
              </v-btn>
              <div style="text-align: left">
                <!--<v-btn  to="/empresa" @click="scrollTo('empresa_pg')"  block outlined>Saiba +</v-btn>-->
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="4" style="text-align: center">
             <div style="text-align: center ">
               <h2 class="txt_branco"  style="font-weight: bold">Setores</h2>
               <br>
               <v-row justify="center" no-gutter>
                 <v-col cols="12">
                   <div class="footer_setores_div">
                     <span class="footer_setores" @click="irPara('como_funciona','center')" >Como Funciona</span>
                     <span class="footer_setores" @click="irPara('recursos','start')" >Recursos</span>
                     <span class="footer_setores" @click="irPara('valor','start')" >Valor</span>
                     <span class="footer_setores" @click="irPara('duvidas','start')" >Duvidas</span>
                     <span class="footer_setores" @click="irPara('parcerias','start')" >Parcerias</span>
                   </div>
                 </v-col>
                 <!--REDES SOCIAIS-->
                 <!--<v-col cols="12">-->
                   <!--<h2 class="txt_branco"  style="font-weight: bold">Redes Sociais</h2>-->
                   <!--<br>-->
                   <!--<v-row justify="center" align="center" >-->
                     <!--<v-col cols="12">-->
                       <!--<v-row justify="center" >-->
                         <!--<v-col cols="3" sm="3" md="3" lg="2" v-for="(img,index) in icones.getIcones()" :key="'icones'+index">-->
                           <!--<v-btn :name="img.nome" icon @click="IrParaUrl(img.link)">-->
                             <!--<v-img :src="img.src" style="height: 30px; width: 100%" contain ></v-img>-->
                           <!--</v-btn>-->
                         <!--</v-col>-->
                       <!--</v-row>-->
                     <!--</v-col>-->
                   <!--</v-row>-->
                 <!--</v-col>-->
               </v-row>
             </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <!--SITE SEGURO-->
              <site-seguro classe="txt_branco" url="https://uaiped.com.br"></site-seguro>
              <v-row>
                <!--FORMAS DE PAGAMENTO-->
                <!--<v-col cols="12">-->
                  <!--<h2 class="txt_branco"  style="font-weight: bold"> Formas de pagamento</h2>-->
                  <!--<br>-->
                  <!--<v-row justify="center">-->
                    <!--<v-col cols="12" >-->
                      <!--<v-img :src="bandeira" style="height: 50px; width: 100%" contain></v-img>-->
                    <!--</v-col>-->
                  <!--</v-row>-->
                <!--</v-col>-->
              </v-row>

              </v-col>
            <v-col cols="12">

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="py-2 white--text text-center rodade_gradiente_dark">
        <span>Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —  <a href="http://www.prodatanet.com.br/" style="color: white; text-decoration: none"><b>Prodata Informática</b></a>. Todos os direitos reservados.</span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script async>
/* eslint-disable */
import icones from '../assets/js/img_icones'
import miscMixin from '../helpers/misc'
  export default {
    name: 'Rodape',
    mixins: [miscMixin],
    data: () => ({
      icones,
      icons: [
        'mdi-youtube',
        'mdi-email',
        'mdi-facebook',
        'mdi-instagram',
      ],
    }),
    methods: {
    },
    components: {
      SiteSeguro: () => import('./SiteSeguro/SiteSeguro.vue')
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    watch: {}
  }
</script>

<style scoped>

</style>
