import { render, staticRenderFns } from "./Rodape.vue?vue&type=template&id=525732a6&scoped=true&"
import script from "./Rodape.vue?vue&type=script&async=true&lang=js&"
export * from "./Rodape.vue?vue&type=script&async=true&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525732a6",
  null
  
)

export default component.exports